import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import main_bg from "../../../../common/images/main_bg.png";
import WarningToastify from "../../common/WarningToastify";
// import GetMainEvent from "../../../Feat/event/GetMainEvent";

const MainIntroduceSlide = () => {
  const [mainEvent, setMainEvent]: any = useState([]);
  const customTheme = {
    root: {
      base: "relative h-full w-full border",
      leftControl:
        "w-fit absolute top-0 h-full flex px-[120px] main-slider-arrow",
      rightControl:
        "w-fit absolute top-0 right-0 h-full flex px-[120px] main-slider-arrow",
    },
    indicators: {
      active: {
        off: "bg-[#FEEDC0] hover:bg-[#FCC22F] hidden",
        on: "bg-[#FCC22F] dark:bg-gray-800 hidden",
      },
      base: "h-2 w-2 rounded-full",
      wrapper:
        "absolute bottom-[-20px] left-1/2 flex -translate-x-1/2 space-x-3",
    },
  };

  return (
    <div>
      <Carousel className="w-full h-[678px]" theme={customTheme}>
        <div className="main-background h-[678px]  justify-center flex">
          <div className="flex flex-col  mx-[261px] w-full mt-[90px] h-fit">
            <div>
              <div className="text-[64px] font-bold">TIPTAP</div>
              <div className="text-[45px] font-bold">
                반려동물 NFT 마켓플레이스
              </div>
            </div>

            <div className="flex content-between justify-between mt-[53px]">
              <div className="flex flex-col text-[20px] font-semibold">
                <span>세계최초 유기동물 기부 NFT 마켓플레이스, TIPTAP</span>
                <span>
                  반려동물의 네 발자국과 우리의 두 발자국이 함께 이루어가는
                  세상을 꿈꿉니다.
                </span>
              </div>
              <div className="flex space-x-[23px]">
                <button
                  type="button"
                  className="main-slider-button bg-black"
                  onClick={() => {
                    window.location.href = "/nftList";
                  }}
                >
                  NFT 보러가기
                </button>
                <button
                  type="button"
                  className="main-slider-button bg-brand100"
                  onClick={() => {
                    if (sessionStorage.getItem("login_user") !== null) {
                      const userStatusString: any =
                        sessionStorage.getItem("login_user");
                      const userStatus = JSON.parse(userStatusString);
                      if (userStatus.role == "ROLE_AGENCY") {
                        window.location.href = "/nftUpload";
                      } else {
                        WarningToastify("기관 계정으로 로그인해주세요.");
                      }
                    }
                  }}
                >
                  NFT 생성하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default MainIntroduceSlide;
