import KlaytnSetProvider from "./KlaytnSetProvider";
import KlaytnIsApprovalForAll from "./KlaytnIsApprovalForAll";
import KlaytnSetIsApprovalForAll from "./KlaytnSetIsApprovalForAll";
import SendSellNFTInfoToServer from "../SendSellNFTInfoToServer";
const router_abi = require("../../../../utils/contract/MarketplaceRouter.json");

const KlaytnSellNFT = async (
  price: any,
  nftId: any,
  date: any,
  serverNftId: any,
  networkName: any
) => {
  let router_contract: any;
  let nft_contract: any;

  if (networkName == "BAOBAB") {
    router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT || "";
    nft_contract = process.env.REACT_APP_KLAYTN_NFT_CONTRACT || "";
  } else if ((networkName = "WHATCON")) {
    router_contract =
      process.env.REACT_APP_ROUTER_WHATCON_CONTRACT_ADDRESS || "";
    nft_contract = process.env.REACT_APP_WHATCON_NFT_CONTRACT || "";
  }

  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }

    let isApproved: any = await KlaytnIsApprovalForAll(
      router_contract,
      networkName
    );

    if (!isApproved.data.isApproved) {
      await KlaytnSetIsApprovalForAll(router_contract, true, networkName);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(router_abi.abi, router_contract);
    contract.options.gas = 5000000;
    let buy_auction_contract: any;
    if (networkName == "BAOBAB") {
      buy_auction_contract =
        process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS;
    } else if (networkName == "WHATCON") {
      buy_auction_contract =
        process.env.REACT_APP_BUY_AUCTION_WHATCON_CONTRACT_ADDRESS;
    }

    const result = await contract.methods
      .creationAuction(
        buy_auction_contract,
        nftId,
        nft_contract,
        String(price * 10 ** 18),
        Number(24 * 60 * 60 * date),
        "0x0000000000000000000000000000000000000000"
      )
      .send({ from: account });

    if (result.status && result.transactionhash != "") {
      const data = {
        // nftId: serverNftId,
        // sellerWalletAddress: result.events._Create.returnValues.creater,
        salePeriod: date,
        price: price,
        auctionNum: result.events._Create.returnValues._index,
        saleTxHash: result.transactionHash,
        // network: networkName,
      };

      const res = await SendSellNFTInfoToServer(data, serverNftId);

      return {
        success: true,
        data: {
          creator: result.events._Create.returnValues.creater,
          index: result.events._Create.returnValues._index,
        },
        transactionHash: result.transactionHash,
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnSellNFT;
