// 체인아이디 값 주면 네트워크 이름 알려줌
const MatchChainIdAndNetworkName = (chainId: string) => {
  if (chainId == "0xaa36a7") {
    return "SEPOLIA";
  } else if (chainId == "0x19") {
    return "CRONOS";
  } else if (chainId == "0x38") {
    return "BNB";
  } else if (chainId == "1001") {
    return "BAOBAB";
  } else if (chainId == "1004") {
    return "WHATCON";
  } else {
    return false;
  }
};

export default MatchChainIdAndNetworkName;
