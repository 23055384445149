import { useEffect, useState } from "react";
import LeftArrow from "../../../common/svg/LeftArrow";
import instance from "../../Feat/axios/instance";
import NotiToastify from "../common/NotiToastify";
import WarningToastify from "../common/WarningToastify";

const MyPageEditContent = () => {
  const [name, setName] = useState("");
  const [originName, setOriginName] = useState("");
  const getUserInfo = async () => {
    try {
      const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/users/profiles";
      const response = await instance.get(url);
      setOriginName(response.data.data.name);
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  const requestEditName = async () => {
    try {
      const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/users/profiles";
      const response = await instance.put(url, { name });
      if (response.status == 200) {
        NotiToastify("닉네임이 정상적으로 변경되었습니다.");
        window.location.reload();
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div className="flex sm:flex-row flex-col w-full  sm:space-x-[80px] justify-center">
      <div className="flex flex-col justify-center w-full px-[261px]  h-full ">
        <div className="flex items-center mt-[88px]">
          <LeftArrow width={36} height={36} fillColor="#868686" />
          <div className="text-[20px] font-semibold ml-[35px]">
            마이페이지 &gt; 내 정보 수정
          </div>
        </div>
        <div>
          <div className="text-[45px] font-semibold mb-[65px] mt-[60px]">
            <span className="mr-[10px]">{originName}</span>
            님, 안녕하세요
          </div>
          <div className="flex items-center">
            <h3 className="mr-[115px] text-brand100 text-[20px] font-semibold">
              이름
            </h3>
            <input
              type="text"
              placeholder="닉네임 입력"
              className="w-[265px] h-[42px] rounded-[30px] border-[#CCCCCC] text-[#CCCCCC] p-5"
              defaultValue={originName}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
            />
          </div>
          <hr className="w-full max-w-[1394px] mt-[75px] mb-[88px]" />
          <div className="flex w-full items-center justify-center space-x-[14px] cursor-pointer">
            <button
              type="button"
              className="w-[211px] h-[63px] text-white bg-[#CCCCCC] rounded-[30px] flex items-center justify-center text-[30px]"
              onClick={() => {
                window.location.href = "/mypage";
              }}
            >
              취소
            </button>
            <button
              type="button"
              className="w-[211px] h-[63px] text-white bg-brand100 rounded-[30px] flex items-center justify-center text-[30px]"
              onClick={() => {
                requestEditName();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPageEditContent;
