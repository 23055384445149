import { useEffect, useState } from "react";
import SellNFT from "../../../Feat/nft/SellNFT";
import NotiToastify from "../../common/NotiToastify";
import IsSellingModalContent from "./IsSellingModalContent";
import Modal from "../../common/Modal";
import WarningToastify from "../../common/WarningToastify";
import KlaytnSellNFT from "../../../Feat/nft/klaytn/KlaytnSellNFT";
import { ConnectMetaMask } from "../../../Feat/wallet/ConnectMetaMask";
import ValidateLoginWalletList from "../../../Feat/wallet/ValidateLoginWalletList";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import KlaytnWhatconSellNFT from "../../../Feat/nft/klaytn/KlaytnWhatconSellNFT";
import SendSellNFTInfoToServer from "../../../Feat/nft/SendSellNFTInfoToServer";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";

const WritePriceAndDateBuyModalContent = (props: any) => {
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState(0);
  const tokenId = props.props.tokenId;
  const nftId = props.props.nftId;
  const [isActiveSelling, setIsActiveSelling] = useState(false);

  return (
    <div className="flex flex-col">
      <Modal
        content={<IsSellingModalContent />}
        isActiveModal={isActiveSelling}
        closeModal={setIsActiveSelling}
      />
      <h3 className="text-[16px]">price</h3>
      <input
        type="text"
        maxLength={8}
        onChange={(e: any) => {
          setPrice(e.target.value);
        }}
      />
      <h3>date</h3>
      <input
        min={1}
        type="number"
        onChange={(e: any) => {
          setDate(e.target.value);
        }}
      />
      <button
        type="button"
        onClick={async () => {
          const userStatusString: any = sessionStorage.getItem("login_user");
          const userStatus = JSON.parse(userStatusString);
          const priceNumber = Number(price);

          if (isNaN(priceNumber) || priceNumber <= 0) {
            alert("가격은 숫자 형식으로만 입력할 수 있으며 0보다 커야 합니다.");
            setIsActiveSelling(false);
            return;
          }

          if (date <= 0) {
            WarningToastify("판매 일자는 1일 이상이어야 합니다.");
            setIsActiveSelling(false);
            return;
          }
          if (
            props.props.detailData.network == "BAOBAB" ||
            props.props.detailData.network == "WHATCON"
          ) {
            const currentWallet = await ConnectKaikas();

            if (props.props.detailData.walletAddress !== currentWallet) {
              WarningToastify("민팅한 지갑주소로 연결해주세요");
              return;
            }

            setIsActiveSelling(true);
            if (
              props.props.detailData.network == "BAOBAB" ||
              props.props.detailData.network == "WHATCON"
            ) {
              const { chainId }: any = await CheckCurrentKlaytnNetwork();
              const networkName: any = MatchChainIdAndNetworkName(chainId);

              if (
                networkName == "WHATCON" &&
                props.props.detailData.network == "BAOBAB"
              ) {
                WarningToastify("바오밥 네트워크로 연결 후 재시도해주세요 ");
                setIsActiveSelling(false);

                return;
              }

              if (
                networkName == "BAOBAB" &&
                props.props.detailData.network == "WHATCON"
              ) {
                WarningToastify("왓콘 네트워크로 연결 후 재시도해주세요 ");
                setIsActiveSelling(false);

                return;
              }

              const res: any = await KlaytnSellNFT(
                price,
                tokenId,
                date,
                nftId,
                props.props.detailData.network
              );
              if (res.success) {
                setIsActiveSelling(false);
                window.location.href = "/mypage";
                NotiToastify("판매가 정상적으로 완료되었습니다.");
              }
            }
          }
        }}
        className=" p-3 mt-[20px] bg-brand100 text-white"
      >
        확인
      </button>
      <button
        type="button"
        className="bg-brand100 mt-[10px] text-white h-[48px] cursor-pointer"
        onClick={() => {
          window.location.href = `/mypage/nftDetail/${nftId}`;
        }}
      >
        취소
      </button>
    </div>
  );
};

export default WritePriceAndDateBuyModalContent;
