import NewNFTIntroSlide from "./NewNFTIntroSlide";
import { Trans, useTranslation } from "react-i18next";

const NewNFTContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="sm:flex flex-col w-full px-[260px] hidden  mt-[70px] min-h-screen">
      <div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            window.location.href = "/nftList";
          }}
        >
          <span className="font-bold sm:text-[21px]">신규 NFT</span>
          <span className="text-brand100 text-[20px] ml-[10px] pb-[5px] font-semibold">
            &gt;
          </span>
        </div>
      </div>
      <NewNFTIntroSlide />
    </div>
  );
};

export default NewNFTContainer;
