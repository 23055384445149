import NftUploadContainer from "../../components/UI/nftUpload/NftUploadContainer";

const NftUploadPage = () => {
  return (
    <div>
      <NftUploadContainer />
    </div>
  );
};

export default NftUploadPage;
