import { useEffect, useState } from "react";
import IsBuyingModalContent from "./IsBuyingModalContent";
import Modal from "../../common/Modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import WarningToastify from "../../common/WarningToastify";
import KlaytnBuyingNFT from "../../../Feat/nft/klaytn/KlaytnBuyingNFT";
import NotiToastify from "../../common/NotiToastify";
import NftListNFTContent from "./NftListNFTContent";

const NFTListDetail = () => {
  const [isActiveBuying, setIsActiveBuying] = useState(false);
  const { id } = useParams();
  const [data, setData]: any = useState([]);
  const [activeBuyingButton, setActiveBuyingButton] = useState(false);

  const fetchNftIdDetailNftInfo = async () => {
    try {
      const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/nfts/info/" + id;
      const res = await axios.get(url);
      setData(res.data.data);
      if (sessionStorage.getItem("login_user")) {
        const userStatusString: any = sessionStorage.getItem("login_user");
        const userStatus = JSON.parse(userStatusString);
        const currentWalletAddress = await ConnectKaikas();

        if (
          userStatus.role == "ROLE_USER" &&
          res.data.data.walletAddress !== currentWalletAddress
        ) {
          setActiveBuyingButton(true);
        }
      }

      return true;
    } catch (error) {
      alert("잘못된 네트워크 요청입니다.");
      window.location.href = "/nftList";
      return false;
    }
  };

  const kaikasBuying = async () => {
    if (data !== null) {
      if (data && data.auctionNum && data.price && data.nftId && data.saleId) {
        const { chainId }: any = await CheckCurrentKlaytnNetwork();
        const networkName: any = MatchChainIdAndNetworkName(chainId);

        if (data.network == "BAOBAB" && networkName == "WHATCON") {
          WarningToastify("바오밥 네트워크로 연결해주세요!");
          return;
        } else if (data.network == "WHATCON" && networkName == "BAOBAB") {
          WarningToastify("왓콘 네트워크로 연결해주세요!");
          return;
        }

        setIsActiveBuying(true);
        const result = await KlaytnBuyingNFT(
          data.auctionNum,
          data.price,
          data.nftId,
          data.saleId,
          networkName
        );
        if (result) {
          NotiToastify("구매가 완료되었습니다.");
          setIsActiveBuying(false);
          window.location.href = "/nftList";
        } else {
          NotiToastify("구매에 실패했습니다.");
          setIsActiveBuying(false);
        }
      } else {
        NotiToastify("구매에 실패했습니다.");
        setIsActiveBuying(false);
        return;
      }
    }
  };

  useEffect(() => {
    fetchNftIdDetailNftInfo();
  }, []);

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <Modal
        content={<IsBuyingModalContent />}
        isActiveModal={isActiveBuying}
        closeModal={setIsActiveBuying}
      />
      <NftListNFTContent data={data} />
      {activeBuyingButton && (
        <div className="w-full flex items-center justify-center my-[12%]">
          <button
            type="button"
            className=" w-[211px] h-[63px] bg-brand100 text-white rounded-[30px] flex items-center justify-center text-[30px]"
            onClick={() => {
              kaikasBuying();
            }}
          >
            구매하기
          </button>
        </div>
      )}
    </div>
  );
};

export default NFTListDetail;
