import MyPageNftDetail from "../../../components/UI/nftDetail/mypage/MyPageNftDetail";

const MyNFTDetail = () => {
  return (
    <div>
      <MyPageNftDetail />
    </div>
  );
};

export default MyNFTDetail;
