import { useEffect, useState } from "react";
import MappingCurrencyType from "../../../Feat/common/MappingCurrencyType";
import LeftArrow from "../../../../common/svg/LeftArrow";

const NftListNFTContent = (detailData: any) => {
  const { data } = detailData;
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    const currencyRes: any = MappingCurrencyType(data.network);
    setCurrency(currencyRes);
  }, []);
  return (
    <div className="flex sm:flex-row flex-col w-full  sm:space-x-[80px] justify-center mt-[100px]">
      <div className="flex flex-col justify-center w-full max-w-[560px]  h-full ">
        <div className="flex items-center ">
          <LeftArrow width={36} height={36} fillColor="#868686" />
          <div className="text-[20px] font-semibold ml-[35px]">
            NFT LIST &gt; 상세정보
          </div>
        </div>

        <img src={data?.image} className="nfttype-img" />
      </div>
      <div className=" max-w-[600px] w-full">
        <div className="flex flex-row content-between justify-between  sm:mt-0 mt-[20px]">
          <h3 className="nfttype-main-title">{data?.name}</h3>
        </div>
        <div className="mt-[60px] flex w-full flex-col ">
          <div className="flex flex-row w-full h-[81px]  items-center border-b-[#E5E5E5] border-b-[1px]">
            <span className="nfttype-content-title">가격</span>
            <div className="w-[360px] overflow-hidden flex  items-center">
              <span className="font-semibold text-[40px]">{data?.price}</span>
              <span className="ml-3 text-[#868686] font-semibold text-[18px] text-opacity-95">
                {currency}
              </span>
            </div>
          </div>
          <div className="flex flex-row h-[81px]  w-full items-center  border-b-[#E5E5E5] border-b-[1px]">
            <span className="nfttype-content-title">소유자 지갑주소</span>
            <div>
              <div className=" w-[360px] text-[18px]">
                {data?.walletAddress}
              </div>
            </div>
          </div>

          <div className="nfttype-content-div">
            <span className="nfttype-content-title">설명</span>
            <span className=" text-[18px] text-[#404040] w-full max-w-[490px]">
              {data?.description}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftListNFTContent;
