import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NftListCard from "./NftListCard";
import GetBuyingNFTList from "../../Feat/nft/GetBuyingNFTList";
import { searchKeywordState } from "../../../utils/recoil/searchKeyword/atom";
import { useRecoilState } from "recoil";
import CustomSelect from "../common/CustomSelect";
import nftlist_img from "../../../common/images/nftlist_back_icon.png";

const NftContainer = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const [nftList, setNftList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywordState);
  const [networkTypeIsOpen, setNetworkTypeIsOpen] = useState(false);
  const [network, setNetwork] = useState<string>("체인별 필터");
  const networkTypeOption = ["전체", "바오밥", "왓콘"];
  const networkTypeMapping: any = {
    전체: "ALL",
    왓콘: "WHATCON",
    바오밥: "BAOBAB",
  };

  const [sortByTypeIsOpen, setSortByTypeIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState("최신순");
  const sortByTypeOption = [
    "최신순",
    "종료 임박 순",
    "가격 높은 순",
    "가격 낮은 순",
  ];
  const sortByTypeMapping: any = {
    최신순: "RECENT",
    "가격 높은 순": "PRICE_DESC",
    "가격 낮은 순": "PRICE_ASC",
    "종료 임박 순": "ENDING_SOON",
  };

  const fetchNFTListOnPageChange = async (selectedPage: any) => {
    try {
      const response: any = await GetBuyingNFTList({
        keyword: searchKeyword,
        network: networkTypeMapping[network],
        sortBy: sortByTypeMapping[sortBy],
        page: selectedPage - 1,
        size: itemsPerPage,
      });

      if (response.data && response.data.status === 200) {
        setNftList(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      } else {
        console.error("NFT 목록을 불러오는데 실패했습니다.");
      }
    } catch (error) {}
  };

  const handlePageChange = (selectedPage: any) => {
    setPage(selectedPage);
    fetchNFTListOnPageChange(selectedPage);
  };
  const fetchNFTList = async () => {
    try {
      const response: any = await GetBuyingNFTList({
        keyword: searchKeyword,
        network: networkTypeMapping[network],
        sortBy: sortByTypeMapping[sortBy],
        page: page - 1,
        size: itemsPerPage,
      });
      if (response.data && response.data.status === 200) {
        setNftList(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      } else {
        console.error("NFT 목록을 불러오는데 실패했습니다.");
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchNFTList();
  }, [page, network, sortBy]);

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className="sm:mt-[80px] mt-[40px] flex flex-col items-center max-w-[calc(1280px+60px)] ">
        <div className="flex flex-col justify-center w-full mb-[40px]  bg-[#F4F4F4] p-[60px] h-[142px] rounded-[71px] relative">
          <h3 className="sm:text-[45px] text-[32px] font-bold ">NFT LIST</h3>
          <h4 className="sm:text-[20px] text-[#707070] ">현재 판매중인 NFT</h4>
          <img src={nftlist_img} className="absolute right-0 mr-[100px]" />
        </div>
        <div className="w-full md:min-w-[1200px] flex  sm:mt-[50px] items-end justify-end">
          <div className="flex sm:space-x-4 sm:flex-row flex-col ">
            <CustomSelect
              selectOption={network}
              setSelectedOption={setNetwork}
              isOpen={networkTypeIsOpen}
              setIsOpen={setNetworkTypeIsOpen}
              options={networkTypeOption}
            />
            <CustomSelect
              selectOption={sortBy}
              setSelectedOption={setSortBy}
              isOpen={sortByTypeIsOpen}
              setIsOpen={setSortByTypeIsOpen}
              options={sortByTypeOption}
            />
          </div>
        </div>
        {nftList.length == 0 && (
          <div className="min-h-screen flex items-center justify-center w-full  text-brand100  text-[20px]">
            * 해당하는 데이터가 없습니다.
          </div>
        )}

        {nftList.length !== 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 mt-[80px] w-full min-h-screen mb-[80px]">
            {nftList.map((item: any, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <NftListCard item={item} key={index} />
              </div>
            ))}
          </div>
        )}

        <Pagination
          activePage={page}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalPages * itemsPerPage}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
          activeClass=" text-brand100 text-[22px] font-semibold "
          disabledClass="text-[#9f9f9f]"
          hideFirstLastPages={true}
          innerClass="text-[#9f9f9f]"
          prevPageText={<div className="text-brand100">&#60;</div>}
          nextPageText={<div className="text-brand100">&#62;</div>}
        />
      </div>
    </div>
  );
};

export default NftContainer;
