import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import instance from "../../Feat/axios/instance";
import WarningToastify from "../common/WarningToastify";
import CheckCurrentKlaytnNetwork from "../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import KaikasMatchChainIdAndNetworkName from "../../Feat/network/klaytn/KaikasMatchChainIdAndNetworkName";
import MyNftDetailCard from "./MyNftDetailCard";

const MyPageContent = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 12;
  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage - 1);
  };

  const [filterType, setFilterType] = useState("ALL");
  const [userWallet, setUserWallet] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [myDataList, setMyDataList] = useState<ItemType[]>([]);
  const [totalItem, setTotalItem] = useState(0);

  const getUserNFTList = async () => {
    try {
      const { chainId, account }: any = await CheckCurrentKlaytnNetwork();
      const networkName: any = KaikasMatchChainIdAndNetworkName(chainId);

      setUserWallet(account);
      setNetworkName(networkName);
      const url =
        process.env.REACT_APP_TIPTEP_SERVER_API +
        `/users/mynfts?filter=${filterType}&page=${page}&size=${12}`;

      const response = await instance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      if (response.data) {
        setMyDataList(response.data.data.content);
        const totalElements = response.data.data.totalElements;
        setTotalItem(totalElements);
      } else {
        setMyDataList([]);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  const handleFilterClick = (filter: string) => {
    if (filter === "전체") {
      setFilterType("ALL");
    } else if (filter === "판매중") {
      setFilterType("SALE");
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      getUserNFTList();
    } else {
      WarningToastify("로그인을 먼저 진행해주세요.");
      window.location.href = "/";
      return;
    }
  }, [filterType, page]);
  return (
    <div className="flex items-center justify-center flex-col">
      <div className="flex sm:content-between sm:justify-between w-full  bg-[#F4F4F4] rounded-[71px] items-center p-[67px] h-[142px] mt-[88px]  max-w-[calc(1200px+60px)] ">
        <div className="flex flex-col">
          <h3 className=" text-[45px] font-semibold">MY PAGE</h3>
          <h3 className="text-gray100 text-[20px] ">마이페이지</h3>
        </div>
        <button
          type="button"
          onClick={() => {
            navigate("/mypage/edit");
          }}
          className="border border-[#CCCCCC] rounded-[30px] bg-white h-[35px] w-[120px] text-gray80 text-[20px] "
        >
          내정보 수정
        </button>
      </div>
      <div className="flex flex-col mt-[20px]  max-w-[calc(1280px+60px)] ">
        <div className="flex mt-[70px] sm:flex-row flex-col  ">
          <div className="sm:flex hidden flex-col  ">
            <h3 className=" font-bold text-[25px]">로그인 지갑주소 </h3>
            <h3 className="text-gray100 mt-2">{userWallet}</h3>
          </div>

          <div className="flex flex-col ml-[160px] ">
            <h3 className="  font-bold text-[25px]">연결된 네트워크 </h3>
            <h3 className="text-gray100 mt-1">{networkName}</h3>
          </div>
        </div>

        <div className="sm:flex hidden flex-col mt-[80px] ">
          <div className="flex space-x-[47px]">
            <button
              type="button"
              className={`${
                filterType === "ALL" ? " text-[#05e1ff]" : " text-[#606060]"
              }   text-[18px] font-semibold`}
              onClick={() => handleFilterClick("전체")}
            >
              전체
            </button>
            <button
              type="button"
              className={`${
                filterType === "SALE" ? " text-brand100" : " text-[#606060]"
              } text-brand100  text-[18px] font-semibold`}
              onClick={() => handleFilterClick("판매중")}
            >
              판매중
            </button>
          </div>
          <hr className="border-[1.2px] mt-[20px]" />
        </div>
        {myDataList.length == 0 && (
          <div className="flex min-h-screen items-center justify-center sm:min-w-[1020px] text-brand100">
            * 나의 데이터가 없습니다.
          </div>
        )}
        {myDataList.length !== 0 && (
          <div className="w-full mt-[40px]  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4  lg:min-w-[1120px] md:min-w-[860px] sm:min-w-[680px] gap-7 min-h-screen mb-[80px]">
            {myDataList.map((item, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <MyNftDetailCard item={item} />
              </div>
            ))}
          </div>
        )}

        {/* 페이지네이션 */}
        <div className="flex w-full items-center justify-center">
          {/* Pagination 컴포넌트에 tailwind CSS 클래스 추가 */}
          <Pagination
            activePage={page + 1}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItem}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
            activeClass=" text-brand100 text-[22px] font-semibold "
            disabledClass="text-[#9f9f9f]  "
            hideFirstLastPages={true}
            innerClass="text-[#9f9f9f]"
            prevPageText={<div className="text-brand100">&#60;</div>}
            nextPageText={<div className="text-brand100">&#62;</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default MyPageContent;
