// @ts-ignore
import { NFTStorage } from "nft.storage";

const client = new NFTStorage({
  token: process.env.REACT_APP_NFT_STORAGE_API_KEY as string,
});

const UploadDataToNftStorage = async (mintingNft: any) => {
  try {
    const res: any = await client.store(mintingNft);
    const data = res.data;
    const ipfsUrl = res.url;
    const replaceIpfsUrl = ipfsUrl.replace("ipfs://", "https://ipfs.io/ipfs/");
    const imageUri: any = res.data.image.href;
    const replaceImageUrl = imageUri.replace(
      "ipfs://",
      "https://ipfs.io/ipfs/"
    );
    data.image = replaceImageUrl;

    return { status: true, data: data, ifpsURL: replaceIpfsUrl };
  } catch (error) {
    // console.log("error:", error);
    return { status: false };
  }
};

export default UploadDataToNftStorage;
