import { useSearchParams } from "react-router-dom";
import BuyPayment from "../../components/Feat/payment/BuyPayment";
import SendBuyNFTInfoToServer from "../../components/Feat/nft/SendBuyNFTInfoToServer";
import NotiToastify from "../../components/UI/common/NotiToastify";
import { useEffect } from "react";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const nftId = searchParams.get("nftId");
  const saleId = searchParams.get("saleId");
  const auctionNum = searchParams.get("auctionNum");

  const buyNFT = async () => {
    const res: any = await BuyPayment(auctionNum);
    if (res.result) {
      const data = {
        saleId: saleId,
        nftId: nftId,
        buyerWalletAddress: res.buyerWalletAddress,
        buyTransactionHash: res.buyTransactionHash,
        network: "WHATCON",
      };
      const serverResult = await SendBuyNFTInfoToServer(data, "networkName");
      if (serverResult) {
        NotiToastify("구매에 성공하였습니다.");
        window.location.href = "/nftList";
      }
    }
  };

  useEffect(() => {
    buyNFT();
  }, []);

  return (
    <div className="flex flex-col w-full items-center justify-center"></div>
  );
};

export default PaymentSuccess;
