import WarningToastify from "../../UI/common/WarningToastify";
import instance from "../axios/instance";

const SendBuyNFTInfoToServer = async (data: any, networkName: any) => {
  const sendToServerData = {
    // buyerWalletAddress: data.buyerWalletAddress,
    buyTxHash: data.buyTransactionHash,
    // network: networkName,
  };

  try {
    const url =
      process.env.REACT_APP_TIPTEP_SERVER_API + "/sales/buy/" + data.saleId;
    const response = await instance.put(url, sendToServerData, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return true;
  } catch (error: any) {
    // console.log("서버 error", error);
  }
};

export default SendBuyNFTInfoToServer;
