import React from "react";

const MappingCurrencyType = (curreny: string) => {
  if (curreny == "SEPOLIA") {
    return "ETH";
  } else if (curreny == "BNB") {
    return "BNB";
  } else if (curreny == "CRONOS") {
    return "CRO";
  } else if (curreny == "BAOBAB") {
    return "KLAY";
  } else {
    return "원";
  }
};

export default MappingCurrencyType;
