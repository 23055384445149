import MainIntroduceSlide from "./MainIntroduceSlide";

const IntroduceContainer = () => {
  return (
    <div>
      <MainIntroduceSlide />
    </div>
  );
};

export default IntroduceContainer;
