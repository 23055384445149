import MainContainer from "../../components/UI/main/MainContainer";
import NewNFTContainer from "../../components/UI/main/newNFT/NewNFTContainer";
import MainIntroduceContainer from "../../components/UI/main/introduce/MainIntroduceContainer";
import "../../i18n";

const MainPage = () => {
  return (
    <MainContainer>
      <MainIntroduceContainer />
      <NewNFTContainer />
    </MainContainer>
  );
};

export default MainPage;
