import MyPageEditContent from "../../components/UI/mypage/MyPageEditContent";

const MyPageEdit = () => {
  return (
    <div>
      <MyPageEditContent />
    </div>
  );
};

export default MyPageEdit;
