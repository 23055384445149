import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WarningToastify from "../common/WarningToastify";
import NotiToastify from "../common/NotiToastify";
import instance from "../../Feat/axios/instance";
import CheckCurrentNetwork from "../../Feat/network/CheckCurrentNetwork";
import Modal from "../common/Modal";
import IsMitingModalContent from "./register/IsMitingModalContent";
import UploadSVG from "../../../common/svg/UploadSVG";
import KlaytnMinting from "../../Feat/nft/klaytn/KlaytnMinting";
import UploadDataToNftStorage from "../../Feat/nft/UploadDataToNftStorage";
import MatchChainIdAndNetworkName from "../../Feat/network/MatchChainIdAndNetworkName";
import CheckCurrentKlaytnNetwork from "../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";

const NftUploadContainer = () => {
  const navigate = useNavigate();
  const [isActiveMinting, setIsActiveModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [noticeNetwork, setNoticeNetwork] = useState("");

  const [mintingData, setMintingData] = useState({
    name: "",
    description: "",
    image: null,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | null = e.target.files?.[0] || null;
    setFile(selectedFile);
    setMintingData((prev: any) => ({
      ...prev,
      image: selectedFile,
    }));
  };

  const checkValidateUserStatus = () => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (userStatus.role !== "ROLE_AGENCY") {
        WarningToastify("기관 계정만 해당 페이지에 접근할 수 있습니다.");
        navigate("/");
        return;
      }
    } else {
      NotiToastify("로그인을 먼저 진행해주세요");
      navigate("/");
      return;
    }
  };

  const validateCheck = () => {
    if (mintingData.name == "" || undefined || null) {
      WarningToastify("이름을 입력해주세요.");
      return;
    } else if (mintingData.description == "" || undefined || null) {
      WarningToastify("설명을 입력해주세요.");
      return;
    } else if (mintingData.image == null || undefined || "") {
      WarningToastify("이미지를 업로드 해주세요.");
      return;
    } else if (!isAgreed) {
      WarningToastify("필수 동의에 체크해주세요.");
      return;
    }
    return true;
  };

  useEffect(() => {
    checkValidateUserStatus();
  }, []);
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Modal
        content={<IsMitingModalContent />}
        isActiveModal={isActiveMinting}
        closeModal={setIsActiveModal}
      />
      <div className="flex flex-col items-center justify-center mt-[40px] mb-[120px]">
        <div className="w-full">
          <div className="nfttype-type-button">NFT 등록</div>
          <h3 className="text-[42px] font-semibold w-full mb-[10px] mt-[5px]">
            동물 NFT 생성
          </h3>
          <div className="text-brand100 mb-[20px]"> {noticeNetwork}</div>
        </div>

        <div className="flex mt-[40px] w-full">
          <div>
            <div className="flex">
              <span className="register-form-name">이름</span>
              <input
                type="text"
                placeholder="이름 입력"
                maxLength={30}
                className=" border-gray40  pl-7 text-gray80  h-[42px] w-[240px]   rounded-[30px] "
                onChange={(e: any) => {
                  setMintingData((prev: any) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex mt-[40px] ">
              <span className="register-form-name">설명</span>
              <textarea
                maxLength={300}
                id="description"
                placeholder="설명을 입력해 주세요"
                className="p-5 border-gray40  text-[#8E8E8E]  h-[152px] w-[537px] rounded-[20px]  "
                onChange={(e: any) => {
                  setMintingData((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="sm:ml-[140px] relative  w-[400px]">
              <h3 className="font-bold  text-gray100 mb-[16px]">
                파일 업로드 및 미리보기
              </h3>
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className="sm:w-[420px] sm:h-[420px] relative object-cover "
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[420px] sm:h-[420px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="absolute right-[14px] top-[58px] rounded-[30px] h-[34px] flex items-center justify-center bg-brand100 text-white w-[126px] text-center cursor-pointer"
              >
                <span className="mr-2"> 파일 업로드</span>
                <UploadSVG fillColor="#ffffff" height={16} width={16} />
              </button>

              <div className="flex flex-row items-center w-full mt-[140px]">
                <input
                  type="checkbox"
                  className="rounded-full border border-brand100 checked:bg-brand100"
                  checked={isAgreed}
                  onChange={(e) => {
                    setIsAgreed(e.target.checked);
                  }}
                />
                <h3 className="text-brand100 ml-2 font-semibold">
                  생성된 NFT에 대해 수정이 불가능합니다. 동의하십니까?
                </h3>
              </div>

              <div className="mt-[60px]  w-full flex items-center justify-center">
                <button
                  type="button"
                  className="bg-brand100 text-white w-[140px] h-[46px] rounded-[30px] text-[22px]"
                  onClick={async () => {
                    const validateStatus = validateCheck();
                    if (validateStatus) {
                      setIsActiveModal(true);
                      const responseNft: any = await UploadDataToNftStorage(
                        mintingData
                      );

                      if (responseNft.status) {
                        const data = responseNft.data;
                        const ipfsURI: any = responseNft.ifpsURL;

                        if (
                          data !== null &&
                          data !== undefined &&
                          ipfsURI !== null &&
                          ipfsURI !== undefined
                        ) {
                          const { chainId }: any =
                            await CheckCurrentKlaytnNetwork();
                          const networkName: any =
                            MatchChainIdAndNetworkName(chainId);
                          const result: any = await KlaytnMinting(
                            ipfsURI,
                            networkName
                          );
                          if (result.success == true && result.data.NFTId) {
                            data.tokenId = result?.data.NFTId;
                            data.mintTxHash = result?.transactionHash;
                            data.network = networkName;

                            if (
                              data.tokenId !== "" &&
                              data.tokenId !== undefined
                            ) {
                              try {
                                const response = await instance.post(
                                  process.env.REACT_APP_TIPTEP_SERVER_API +
                                    "/nfts",
                                  data,
                                  {
                                    headers: {
                                      "Content-Type":
                                        "application/json;charset=UTF-8",
                                    },
                                  }
                                );

                                if (response.data.status == 200) {
                                  setIsActiveModal(false);
                                  NotiToastify("민팅에 성공했습니다.");
                                  navigate("/mypage");
                                } else {
                                  setIsActiveModal(false);
                                }
                              } catch (error: any) {}
                            } else {
                              setIsActiveModal(false);
                            }
                          } else {
                            setIsActiveModal(false);
                            NotiToastify(
                              "블록체인 민팅에 실패했습니다. 다시 시도해주세요."
                            );
                            return;
                          }
                        }
                      } else if (!responseNft.status) {
                        setIsActiveModal(false);
                        WarningToastify("ipfs 업로드에 실패했습니다.");
                        return;
                      }
                    }
                  }}
                >
                  민팅하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NftUploadContainer;
