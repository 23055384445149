import tiptap_main from "../../../../common/images/tiptap_logo.png";
import { useState } from "react";
import NotiToastify from "../../common/NotiToastify";
import WarningToastify from "../../common/WarningToastify";
import kaikas_logo from "../../../../common/images/kaikas_logo.png";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import axios from "axios";

const RegisterMain = () => {
  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState("ROLE_USER");
  const [walletAddress, setWalletAddress] = useState("");

  const connectKaikas = async () => {
    try {
      const kaikasWalletAddress = await ConnectKaikas();
      setWalletAddress(kaikasWalletAddress);
    } catch (error) {}
  };

  const signUp = async () => {
    if (name !== "" && walletAddress !== "") {
      try {
        const data = {
          name,
          userRole,
          walletAddress,
        };

        const serverAPI = process.env.REACT_APP_TIPTEP_SERVER_API;
        const url = serverAPI + "/auth/sign-up";
        const res = await axios.post(url, data);
        if (res.data.status == 200) {
          NotiToastify("회원가입에 성공했습니다. 로그인해주세요.");
          window.location.href = "/";
        }
      } catch (error: any) {
        if (error.response.data.message) {
          NotiToastify(error.response.data.message);
        } else {
          WarningToastify("회원가입에 실패했습니다.");
          return;
        }
      }
    } else {
      NotiToastify("회원가입 폼을 모두 입력해주세요");
      return;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-[125px]">
      <img src={tiptap_main} className="w-[203px] h-[60px]  " />
      <h3 className="mt-[39px] text-[25px] font-semibold">회원 정보 입력</h3>
      <h3 className=" text-[16px] text-brand100 mt-1">
        *표시가 있는 항목은 필수 입력 항목입니다.
      </h3>

      <div className="flex flex-col  mt-[133px]">
        <div className="flex mb-[50px]">
          <span className="register-form-name">회원 유형</span>
          <div className="flex space-x-[11px]">
            <div
              className={`${
                userRole == "ROLE_USER"
                  ? "register-member-type-button-active"
                  : "register-member-type-button-inactive"
              }`}
              onClick={() => {
                setUserRole("ROLE_USER");
              }}
            >
              일반 회원
            </div>
            <div
              className={`${
                userRole == "ROLE_AGENCY"
                  ? "register-member-type-button-active"
                  : "register-member-type-button-inactive"
              }`}
              onClick={() => {
                setUserRole("ROLE_AGENCY");
              }}
            >
              기업 회원
            </div>
          </div>
        </div>

        <div className="flex mt-[80px]">
          <span className="register-form-name">지갑주소</span>
          <div className="flex flex-col">
            {walletAddress !== "" && (
              <>
                <div className="flex">
                  <input
                    type="text"
                    className="rounded-[20px] bg-[#DFDFDF] text-center text-[#909090] border h-[42px] mb-[23px] border-gray-200 w-[529px]"
                    placeholder="지갑주소 입력"
                    value={walletAddress}
                    disabled
                  />
                  <div
                    className="cursor-pointer w-[109px] h-[42px] rounded-[30px] flex items-center justify-center bg-brand100 ml-[39px]"
                    onClick={() => {
                      setWalletAddress("");
                    }}
                  >
                    <span className="text-[18px] text-white ">삭제</span>
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-col w-full ">
              <div className="flex space-x-3  ">
                <button
                  type="button"
                  className="bg-kaikas_color rounded-[12px] text-[18px] text-white h-[54px] w-[240px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectKaikas();
                  }}
                >
                  <img src={kaikas_logo} className="w-[20px] h-[20px] " />
                  <span className="ml-2"> 카이카스 지갑 연동</span>
                </button>
              </div>
              <span className="text-gray80 text-[15px] mb-[80px] mt-[10px]">
                * 지갑 연동 버튼을 눌러서 지갑주소를 추가할 수 있습니다.
              </span>
            </div>
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name">닉네임</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                maxLength={10}
                type="text"
                className="rounded-[20px] w-[230px] pl-9 text-gray-400 border h-[34px] border-gray-200"
                placeholder="ex) 식스스탭댄스"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="bg-brand100 w-[197px] mt-[157px] mb-[130px] text-[30px] text-white rounded-[30px]   px-3  h-[66px]"
            onClick={() => {
              signUp();
            }}
          >
            가입하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterMain;
