import instance from "../axios/instance";

const SendWithdrawInfoToServer = async (saleId: number) => {
  try {
    const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/sales/" + saleId;
    const response = await instance.delete(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    if (response.status == 201) {
      return true;
    }
  } catch (error: any) {
    // console.log("error:", error);
  }
};

export default SendWithdrawInfoToServer;
