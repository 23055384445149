import MyPageContent from "../../components/UI/mypage/MyPageContent";

const MyPage = () => {
  return (
    <div>
      <MyPageContent />
    </div>
  );
};

export default MyPage;
