import SendBuyNFTInfoToServer from "../SendBuyNFTInfoToServer";
import KlaytnSetProvider from "./KlaytnSetProvider";
const router_abi = require("../../../../utils/contract/MarketplaceRouter.json");

const KlaytnBuyingNFT = async (
  auctionNum: number,
  price: number,
  nftId: number,
  saleId: number,
  networkName: string
) => {
  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);

    //왓콘 체인 추가(시작)
    let router_contract: string;
    if (networkName == "WHATCON") {
      router_contract =
        process.env.REACT_APP_ROUTER_WHATCON_CONTRACT_ADDRESS || "";
    } else if (networkName == "BAOBAB") {
      router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT || "";
    } else {
      return new Error("Invalid networkName");
    }
    //왓콘 체인 추가(끝)
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];

    const contract = new p.Contract(router_abi.abi, router_contract);
    contract.options.gas = 5000000;
    let auction_contract: string;
    if (networkName == "WHATCON") {
      auction_contract =
        process.env.REACT_APP_BUY_AUCTION_WHATCON_CONTRACT_ADDRESS || "";
    } else if (networkName == "BAOBAB") {
      auction_contract =
        process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS || "";
    } else {
      return new Error("Invalid networkName");
    }

    const result = await contract.methods
      .bid(
        auction_contract, // platform
        auctionNum, // platform index - 이 플랫폼에서 생성된 5번째 경매
        String(price * 10 ** 18) // price
      )
      .send({ from: account, value: String(price * 10 ** 18) });

    if (result.status && result.transactionHash != "") {
      const data = {
        saleId: saleId,
        nftId: nftId,
        buyerWalletAddress: result.events._Bid.returnValues.bider,
        buyTransactionHash: result.events._Bid.transactionHash,
      };
      const serverResult = await SendBuyNFTInfoToServer(data, networkName);

      return {
        success: true,
        data: {
          index: result.events._Bid.returnValues._index,
          bider: result.events._Bid.returnValues.bider,
          price: result.events._Bid.returnValues.price,
        },
        transactionHash: result.transactionHash,
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return false;
  }
};

export default KlaytnBuyingNFT;
