import ConnectKaikas from "../../Feat/wallet/klaytn/ConnectKaikas";
import tiptap_main from "../../../common/images/tiptap_logo.png";
import kaikas_logo from "../../../common/images/kaikas_logo.png";
import Login from "../../Feat/auth/Login";

const LoginModalContent = () => {
  return (
    <div className="flex justify-center items-center h-[405px] w-[322px]">
      <div className="justify-center items-center flex flex-col">
        <img src={tiptap_main} className="w-[203px] h-[60px]" />
        <div className="flex flex-col space-y-2 mt-[57px]">
          <button
            type="button"
            className="login-modal-content-button bg-[#3465FF]"
            onClick={async () => {
              const connectWallet = await ConnectKaikas();
              const result: any = await Login(connectWallet);
              if (result) {
                window.location.href = "/";
              }
            }}
          >
            <img src={kaikas_logo} className="w-[22px] h-[22px]" />
            <span className="ml-4"> 카이카스 로그인</span>
          </button>
          <button
            type="button"
            className="login-modal-content-button bg-[#05E1FF]"
            onClick={() => {
              window.location.href = "/register";
            }}
          >
            <span className="ml-3"> 회원가입</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModalContent;
