import MainLayout from "./components/UI/layout/MainLayout";
import RegisterMainPage from "./pages/auth/RegisterMainPage";
import MainPage from "./pages/main/MainPage";
import NftListPage from "./pages/nftList/NftListPage";
import "./styles/App.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EventMainPage from "./pages/event/EventMainPage";
import EventDetailPage from "./pages/event/EventDetailPage";
import ScrollToTop from "./components/UI/common/ScrollToTop";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import PaymentFail from "./pages/payment/PaymentFail";
import NftUploadPage from "./pages/nftUpload/NftUploadPage";
import MyPage from "./pages/mypage/MyPage";
import MyPageEdit from "./pages/mypage/MyPageEdit";
import MyNFTDetail from "./pages/nftDetail/mypage/MyNFTDetail";
import NftDetailPage from "./pages/nftDetail/nftList/NftDetailPage";

function App() {
  return (
    <div className="App">
      <MainLayout>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/event" element={<EventMainPage />} />
          <Route path="/event/detail/:id" element={<EventDetailPage />} />
          <Route path="/register" element={<RegisterMainPage />} />
          <Route path="/nftList" element={<NftListPage />} />
          <Route path="/nftDetail/:id" element={<NftDetailPage />} />
          <Route path="/nftUpload" element={<NftUploadPage />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/mypage/nftDetail/:nftId" element={<MyNFTDetail />} />
          <Route path="/mypage/edit" element={<MyPageEdit />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/fail" element={<PaymentFail />} />
        </Routes>
      </MainLayout>
    </div>
  );
}

export default App;
