import instance from "../axios/instance";

const SendSellNFTInfoToServer = async (data: any, nftId: number) => {
  // const sendToServerData = {
  //   sellerWalletAddress: data.sellerWalletAddress,
  //   dueDate: data.dueDate,
  //   price: data.price,
  //   auctionNumber: data.auctionNumber,
  //   saleTransactionHash: data.saleTransactionHash,
  //   network: data.network,
  // };

  try {
    const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/sales/" + nftId;
    const response = await instance.post(url, data, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    return { data: response.data, result: true };
  } catch (error) {
    return { result: false };
  }
};

export default SendSellNFTInfoToServer;
