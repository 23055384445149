import NFTListDetail from "../../../components/UI/nftDetail/nftList/NFTListDetail";

const NftDetailPage = () => {
  return (
    <div>
      <NFTListDetail />
    </div>
  );
};

export default NftDetailPage;
