import { Carousel } from "flowbite-react";
import axios from "axios";
import { useEffect, useState } from "react";

const NewNFTIntroSlide = () => {
  const [dataList, setDataList] = useState([]);
  const getNewNFTInfo = async () => {
    const url =
      process.env.REACT_APP_TIPTEP_SERVER_API +
      "/nfts/search?network=ALL&sortBy=RECENT&page=0&size=12";
    const response = await axios.get(url);
    setDataList(response.data.data.content);
  };
  const customTheme = {
    root: {
      leftControl: "hidden",
      rightControl: "hidden",
    },
  };

  const imageGroups = Array.from(
    { length: Math.ceil(dataList.length / 6) },
    (_, groupIndex) => dataList.slice(groupIndex * 6, (groupIndex + 1) * 6)
  );

  useEffect(() => {
    getNewNFTInfo();
  }, []);

  return (
    <div className="md:flex hidden h-[260px] w-full mt-[20px]">
      <Carousel theme={customTheme}>
        {imageGroups.map((imageGroup: any, groupIndex) => (
          <div className="flex space-x-[38.74px] h-full" key={groupIndex}>
            {imageGroup.map((imageInfo: any, index: number) => (
              <div
                className="relative"
                key={index}
                onClick={() => {
                  window.location.href = "/nftDetail/" + imageInfo.nftId;
                }}
              >
                <img
                  src={imageInfo.image}
                  className="object-cover aspect-square max-h-[140px] sm:max-h-[190px] sm:max-w-[199px] w-full h-full rounded-lg"
                />
                <div className="flex flex-col ">
                  <div className="flex content-between justify-between items-center mt-[12.8px]">
                    <span className="text-[20px] ">{imageInfo.name}</span>
                    <span className="text-[#DEDEDE] text-[18px] pb-[5px] ">
                      &gt;
                    </span>
                  </div>
                  <div className="flex text-[16px] mt-[3px]">
                    <span className=" text-[#390000]">Price</span>
                    <span className="text-brand100 pl-2">
                      {imageInfo.price}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default NewNFTIntroSlide;
