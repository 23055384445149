import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../common/Modal";
import WritePriceAndDateBuyModalContent from "./WritePriceAndDateBuyModalContent";
import IsWithdrawModalContent from "./IsWithdrawModalContent";
import GetDetailInfoUseNFTIdToServerAsLogin from "../../../Feat/nft/GetDetailInfoUseNFTIdToServerAsLogin";
import MyPageNFTDetailContent from "../MyPageNFTDetailContent";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import KlaytnWithdraw from "../../../Feat/nft/klaytn/KlaytnWithdraw";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import NotiToastify from "../../common/NotiToastify";
import WarningToastify from "../../common/WarningToastify";
const MyPageNftDetail = () => {
  const id: any = useParams();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [detailData, setDetailData]: any = useState([]);
  const [saleId, setSaleId] = useState(0);
  const [tokenId, setTokenId] = useState("");
  const [nftId, setNftId] = useState("");
  const [isActiveWithdraw, setIsActiveWithdraw] = useState(false);
  const [isMine, setIsMine] = useState(false);

  const getInfo = async () => {
    const requestNftId = id.nftId;
    const res: any = await GetDetailInfoUseNFTIdToServerAsLogin(requestNftId);
    setDetailData(res);
    setTokenId(res.tokenId);
    setNftId(res.nftId);
    setSaleId(res.saleId);
    const currentWalletAddress = await ConnectKaikas();
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);

    if (
      currentWalletAddress == res.walletAddress &&
      userStatus.role !== "ROLE_USER"
    ) {
      setIsMine(true);
    }

    return true;
  };

  const sellNFT = () => {
    setIsActiveModal(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      try {
        getInfo();
      } catch (error: any) {}
    } else {
      alert("로그인을 먼저 진행해주세요");
      window.location.href = "/";
      return;
    }
  }, []);

  return (
    <div className="flex flex-col items-center sm:mt-[120px]  ">
      <Modal
        content={
          <WritePriceAndDateBuyModalContent
            props={{ tokenId, nftId, detailData }}
          />
        }
        isActiveModal={isActiveModal}
        closeModal={setIsActiveModal}
      />
      <Modal
        content={<IsWithdrawModalContent />}
        isActiveModal={isActiveWithdraw}
        closeModal={setIsActiveWithdraw}
      />
      <MyPageNFTDetailContent data={detailData} />
      {/* 소유자 지갑주소 == 지금 접속된 지갑주소 일치해야 한다. */}
      {isMine && detailData.saleFlag && (
        <div className="w-full flex items-center justify-center my-[160px]">
          <button
            type="button"
            className=" w-[211px] h-[63px] bg-brand100 text-white rounded-[30px] flex items-center justify-center text-[30px]"
            onClick={async () => {
              const { chainId, account }: any =
                await CheckCurrentKlaytnNetwork();
              const networkName: any = MatchChainIdAndNetworkName(chainId);
              if (detailData.network == networkName) {
                if (detailData.walletAddress == account) {
                  sellNFT();
                } else {
                  WarningToastify("민팅한 지갑주소로 연결해주세요.");
                  return;
                }
              } else {
                WarningToastify("민팅한 네트워크로 연결 후 재시도해주세요.");
                return;
              }
            }}
          >
            판매하기
          </button>
        </div>
      )}

      {!isMine && (
        <div className="w-full flex items-center justify-center  my-[15%]">
          <div className="text-center text-[20px]">
            해당 NFT의 판매를 원하시면
            <br />
            <span className="font-semibold mr-[3px] underline text-[22px]">
              해당 NFT의 소유자 지갑주소
            </span>
            로 연결 후 새로고침 해주세요.
            <br />
            <span className="text-gray80">
              * 유저 회원의 경우 구매한 NFT는 재판매할 수 없습니다.
            </span>
          </div>
        </div>
      )}

      {detailData.saleFlag == false && (
        <div className="w-full flex items-center justify-center my-[160px]">
          <button
            type="button"
            className=" w-[211px] h-[63px] bg-brand100 text-white rounded-[30px] flex items-center justify-center text-[30px]"
            onClick={async () => {
              setIsActiveWithdraw(true);
              const { chainId }: any = await CheckCurrentKlaytnNetwork();
              const networkName: any = MatchChainIdAndNetworkName(chainId);
              if (detailData.network == networkName) {
                const res: any = KlaytnWithdraw(
                  detailData.auctionNum,
                  saleId,
                  networkName
                );
                res.then((item: any) => {
                  if (item.success) {
                    setIsActiveWithdraw(false);
                    NotiToastify("취소가 완료되었습니다.");
                    window.location.href = "/mypage";
                  } else {
                    setIsActiveWithdraw(false);
                    NotiToastify("NFT 판매취소 실패");
                    return;
                  }
                });
              } else {
                WarningToastify("민팅한 네트워크로 연결해주세요");
                setIsActiveWithdraw(false);

                return;
              }
            }}
          >
            판매취소
          </button>
        </div>
      )}
    </div>
  );
};

export default MyPageNftDetail;
