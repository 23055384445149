import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MappingCurrencyType from "../../Feat/common/MappingCurrencyType";

const MyNftDetailCard = (item: any) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/mypage/nftDetail/${item.item.nftId}`);
  };

  const [currencyType, setCurrencyType] = useState<string | undefined>("");

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    const currencyResult: string | undefined = MappingCurrencyType(
      item.item.network
    );
    setCurrencyType(currencyResult);
  }, [item]);

  return (
    <div
      className={`mb-[25px] w-[259px]  h-fit flex flex-col items-center rounded-lg cursor-pointer transform hover:scale-105`}
    >
      <img
        onClick={handleCardClick}
        src={item.item.image}
        className="h-[259px] w-full rounded-[10px] object-cover object-center"
      />
      <div className=" flex flex-col w-full">
        <div className="w-full  flex content-between justify-between items-center mt-[20px] ">
          <div className="text-[20px] font-semibold">
            {truncateText(item.item.name, 8)}
          </div>
        </div>

        <div className="flex justify-centers items-center w-full mt-[10px]">
          <div className=" text-brand100 font-semibold">
            {item.item.price}
            <span className="ml-1">{currencyType}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNftDetailCard;
