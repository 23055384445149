import { useNavigate, useLocation } from "react-router-dom";
import LoginModalContent from "../auth/LoginModalContent";
import tiptap_main_logo from "../../../common/images/tiptap_logo.png";
import RemoveLoginStatusInSession from "../../Feat/auth/RemoveLoginStatusInSession";
import { useRecoilState } from "recoil";
import { searchKeywordState } from "../../../utils/recoil/searchKeyword/atom";
import LoginModal from "../auth/LoginModal";
import { useEffect, useState } from "react";
import { activeMobileMenuListState } from "../../../utils/recoil/mobile/atom";
import HamburgerMenuSVG from "../../../common/svg/HamburgerMenuSVG";
// import { Trans, useTranslation } from "react-i18next";

const Header = () => {
  const navigate = useNavigate();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywordState);
  const location = useLocation();
  const currentPath = location.pathname;
  const [isAgency, setIsAgency] = useState(false);
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useRecoilState(
    activeMobileMenuListState
  );
  // const { t, i18n } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
      return;
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      const role = userStatus.role;
      if (role == "ROLE_AGENCY") {
        setIsAgency(true);
        return;
      } else if (role == "ROLE_USER") {
        setIsAgency(false);
      }
    } else {
      // alert("로그인을 먼저 진행해주세요");
      return;
    }
  }, []);

  return (
    <header className="text-gray-600 body-font w-full  sm:px-[80px] ">
      {/* 로그인 모달 */}
      <LoginModal
        content={<LoginModalContent />}
        isActiveModal={isActiveModal}
        closeModal={setIsActiveModal}
      />
      <div className=" w-full p-5 flex-col sm:flex-row items-center  md:flex hidden">
        <a
          className="flex title-font font-medium items-center cursor-pointer text-gray-900 mb-4 md:mb-0"
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src={tiptap_main_logo}
            className="max-w-[160px] max-h-[47px] w-full h-full sm:my-0 my-[30px]"
          />
        </a>
        {/* "/nftList" 페이지에서만 검색 입력 필드 표시 */}

        <nav className="md:ml-[50px] sm:max-w-[480px]  max-w-[360px] w-full md:mr-auto flex flex-wrap items-center text-base justify-center">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center ">
              <svg
                className="w-[15.9px] h-[15.9px] font-semibold text-[#05E1FF]  ml-[17px] cursor-pointer hover:text-brand100 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                onClick={() => {
                  navigate("/nftList");
                }}
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              className="w-full placeholder:text-brand100 h-[42px] p-4 pl-11 text-[18px] text-brand100 border-[2px] border-brand100 rounded-[30px]"
              placeholder="검색"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  navigate("/nftList");
                }
              }}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
            />
          </div>
        </nav>

        {isLogin ? (
          <div className="space-x-[35px] mt-[30px] sm:mt-0 text-[#616161] sm:text-[20px]  ">
            <button
              onClick={() => {
                window.location.href = "/nftList";
              }}
              className=" inline-flex items-center  rounded"
            >
              NFT 검색
              {/* <Trans>{t("page:header-search-nft")}</Trans> */}
            </button>
            {isAgency && (
              <button
                className=" inline-flex items-center  rounded "
                onClick={() => {
                  navigate("/nftUpload");
                }}
              >
                NFT 생성
                {/* <Trans>{t("page:header-create-nft")}</Trans> */}
              </button>
            )}

            <button
              className=" inline-flex items-center  rounded  "
              onClick={() => {
                navigate("/mypage");
              }}
            >
              마이페이지
              {/* <Trans>{t("page:header-mypage")}</Trans> */}
            </button>
            <button
              className=" inline-flex items-center  rounded"
              onClick={() => {
                const result = RemoveLoginStatusInSession();
                if (result) {
                  window.location.href = "/";
                }
              }}
            >
              로그아웃
              {/* <Trans>{t("page:header-logout")}</Trans> */}
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center space-x-[35px] mt-[30px] sm:mb-0 mb-[30px]  sm:mt-0 text-[#616161] w-full sm:w-[20%]">
            <button
              onClick={() => {
                setIsActiveModal(true);
              }}
              className=" inline-flex items-center  rounded text-[20px] "
            >
              로그인
              {/* <Trans>{t("page:header-login")}</Trans> */}
            </button>
            <button
              className=" inline-flex items-center  rounded text-[20px] "
              onClick={() => {
                window.location.href = "/register";
              }}
            >
              회원가입
              {/* <Trans>{t("page:header-join")}</Trans> */}
            </button>
          </div>
        )}
      </div>

      {/* 모바일 */}
      <div
        className="header-mobile-menu w-full items-center justify-center flex-col"
        onClick={() => {
          setIsActiveMobileMenu(!isActiveMobileMenu);
        }}
      >
        <img
          src={tiptap_main_logo}
          className="w-[120px] h-[40px] sm:mt-0 my-[30px]"
        />
        <div className="w-full bg-[#dfb79a] mb-[20px] flex items-end justify-end">
          <svg
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z"
              fill="#ffffff"
            />
          </svg>
        </div>
      </div>
      {isActiveMobileMenu && (
        <div className="bg-white w-screen h-screen flex flex-col items-center ">
          <div className="w-[80%]">
            {isLogin ? (
              <div className="flex flex-col space-y-3 mt-[30px] sm:mt-0 text-[#E06713] sm:text-[20px]  ">
                <button
                  onClick={() => {
                    window.location.href = "/nftList";
                  }}
                  className=" inline-flex items-center  rounded"
                >
                  NFT 검색
                </button>
                {isAgency && (
                  <button
                    className=" inline-flex items-center  rounded "
                    onClick={() => {
                      navigate("/nftUpload");
                    }}
                  >
                    NFT 생성
                  </button>
                )}

                <button
                  className=" inline-flex items-center  rounded  "
                  onClick={() => {
                    navigate("/mypage");
                  }}
                >
                  마이페이지
                </button>
                <button
                  className=" inline-flex items-center  rounded"
                  onClick={() => {
                    const result = RemoveLoginStatusInSession();
                    if (result) {
                      window.location.href = "/";
                    }
                  }}
                >
                  로그아웃
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-center flex-col space-y-3 mt-[30px] sm:mb-0 mb-[30px]  sm:mt-0 text-[#E06713]  w-full sm:w-[20%]">
                <button
                  onClick={() => {
                    setIsActiveModal(true);
                  }}
                  className=" inline-flex items-center  rounded text-[20px] "
                >
                  로그인
                </button>
                <button
                  className=" inline-flex items-center  rounded text-[20px] "
                  onClick={() => {
                    window.location.href = "/register";
                  }}
                >
                  회원가입
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
