import axios from "axios";
import NotiToastify from "../../UI/common/NotiToastify";

const Login = async (walletAddress: string) => {
  const data = {
    walletAddress: walletAddress,
  };
  const url = process.env.REACT_APP_TIPTEP_SERVER_API + "/auth/login";
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };

  try {
    const response = await axios.post(url, data, {
      headers,
      withCredentials: true,
    });

    if (response) {
      const accessToken = response.data.data.accessToken;
      const role = response.data.data.role;
      const user = {
        loginAddress: walletAddress,
        role: role,
        accessToken: accessToken,
      };
      sessionStorage.setItem("login_user", JSON.stringify(user));
      return true;
    }
  } catch (error: any) {
    if (error.response.data.status == 404) {
      NotiToastify(
        <div>
          등록되지 않은 지갑입니다.
          <br />
          회원가입을 먼저 진행해주세요.
        </div>
      );
      return;
    } else if (error.response.data.status == 400) {
      alert("잘못된 요청입니다. ");
    } else {
      alert("로그인에 실패했습니다.");
    }
    return false;
  }
};

export default Login;
